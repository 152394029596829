<template>
  <ContentSection>
    <Container>
      <div
        class="flex w-full items-start gap-8 bg-gray-10 px-4 py-16 max-lg:-mx-4 max-lg:w-screen max-lg:flex-col lg:gap-20 lg:px-16"
      >
        <div class="lg:basis-1/3 lg:pt-16 xl:basis-2/5">
          <h2 class="text-balance text-md font-bold md:text-xl lg:text-xl">
            {{ data.teaser_title }}
          </h2>
          <Bard
            class="mt-2 text-balance text-sm font-medium text-gray-300"
            :content="data.teaser_text"
          />
          <div v-if="buttonEnabled" class="mt-8 inline-block max-lg:hidden">
            <AppButton
              v-if="data.button_type?.value === 'white'"
              :to="buttonLink"
              variant="tertiary"
            >
              {{ data.button_text }}
            </AppButton>
            <AppLink
              v-else-if="data.button_type?.value === 'arrow'"
              :to="buttonLink"
              :underline="false"
              icon="arrow-right"
              class="flex-row-reverse"
            >
              {{ data.button_text }}
            </AppLink>
          </div>
        </div>
        <div class="-mr-8 w-full min-w-0 lg:-mr-16 lg:grow">
          <ClientOnly>
            <ShopProductSlider
              v-if="products?.length"
              :products="products"
              shadow-classes="from-gray-10"
              size="sm"
            />
          </ClientOnly>
        </div>
        <AppButton
          v-if="data.button_type?.value === 'white'"
          :to="buttonLink"
          variant="tertiary"
          class="lg:hidden"
        >
          {{ data.button_text }}
        </AppButton>
        <AppLink
          v-else-if="data.button_type?.value === 'arrow'"
          :to="buttonLink"
          :underline="false"
          icon="arrow-right"
          class="flex-row-reverse lg:hidden"
        >
          {{ data.button_text }}
        </AppLink>
      </div>
    </Container>
  </ContentSection>
</template>

<script setup lang="ts">
import type { Schemas } from '#shopware'

const props = defineProps<{
  data: {
    teaser_title: string
    teaser_text: string
    product_group: string
    load_category: boolean
    button_type?: {
      value: 'disabled' | 'white' | 'arrow'
    }
    button_text?: string
    button_link_type?: {
      value: 'statamic' | 'category'
    }
    button_category_link?: string[]
    button_statamic_link?: {
      url: string
    }
  }
}>()

const { search: searchProducts } = useProductStream(props.data.product_group)
const { search: searchCategory } = useCategorySearch()
const { getCategoryRoute } = useShopRouter()

const linkCategoryId = computed(
  () => props.data.button_category_link?.[0] ?? ''
)

const buttonEnabled = computed(
  () =>
    props.data.button_type !== undefined &&
    props.data.button_type.value !== 'disabled' &&
    buttonLink.value !== undefined
)

const buttonLink = computed(() => {
  if (
    props.data.button_link_type?.value === 'category' &&
    linkCategory.value !== null
  ) {
    return getCategoryRoute(linkCategory.value)
  }
  if (props.data.button_link_type?.value === 'statamic') {
    return props.data.button_statamic_link?.url
  }
  return undefined
})

const productPromise = useAsyncData(
  `productStream-${props.data.product_group}`,
  async () => {
    const associations: Schemas['Association'] = {
      media: {},
      seoUrls: {},
    }
    if (props.data.load_category) {
      associations.categories = {
        seoUrls: {},
      }
    }
    try {
      const {
        data: { elements },
      } = await searchProducts({
        limit: 10,
        page: 1,
        associations,
      })
      return elements
    } catch (error) {
      console.error(error)
      return []
    }
  },
  {
    lazy: true,
  }
)

const categoryPromise = useAsyncData(
  `teaser-product-large-category-link-${linkCategoryId.value}`,
  async () => {
    const categoryId = linkCategoryId.value
    if (!categoryId) {
      return Promise.resolve(null)
    }
    return await searchCategory(categoryId, {
      query: {
        associations: {
          seoUrls: {},
        },
      },
    })
  }
)

const { data: products } = await productPromise
const { data: linkCategory } = await categoryPromise
</script>
